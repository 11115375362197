.custom-badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.custom-badge-long {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}

.custom-badge-short {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: #E97451;
    color: white;
    cursor: pointer;
  }
